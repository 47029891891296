import React, { useState } from "react"
import videoImge from "../../images/myob/video.png"
import QaItem from "../new-landing/qa-item"
import ReactGA from "react-ga";
export default function Faq({ toggleVideoModal }) {
  const [opens, setOpens] = useState([false, false, false, false])
  const faqClick = (faqTitle) => {
    ReactGA.event({
      category: `Myob_FAQ ${faqTitle}_Arrow`,
      action: `Myob_FAQ ${faqTitle}_Arrow clicked`
    });
  }

  const videoClick = () => {
    ReactGA.event({
      category: `Myob_FAQ video click_Button`,
      action: `Myob_FAQ video click_Button clicked`
    });
    toggleVideoModal();
  }

  return (
    <section className="faq">
      <div className="container flex flex-col  sm:flex-row justify-center items-center">
        <div className="text-left w-full sm:w-auto">
          <h2 className=" text-xl sm:text-2xl sm:font-bold font-black sm:text-left text-center">
            E-invoicing FAQs
          </h2>
          <ul className="faq__list leading-5 text-neutral-3 font-medium">
            {faqs.map(({ title, content }, index) => (
              <QaItem
                title={title}
                content={content}
                key={`item-${index}`}
                index={index}
                setOpens={setOpens}
                opens={opens}
                faqClick={faqClick}
              />
            ))}
          </ul>
        </div>
        <div className="fvideo cursor-pointer hidden sm:block" onClick={videoClick}>
          <div className="fvideo__play" />
          <img src={videoImge} alt="video"  />
        </div>
      </div>
    </section>
  )
}

const faqs = [
  {
    title: "What is e-invoicing? Why is it important?",
    content:
      <div><div className="mb-2">E-invoicing is a new way to send and receive invoices. </div><div className="mb-2">You may already think that you're sending and receiving e-invoices already. </div><div className="mb-2">In fact, unless you implement an e-invoicing provider like LUCA Plus which syncs with your MYOB software: AccountRight (browser), Essentials (new) you’re NOT sending or receiving e-invoices. </div> Best get in touch with our team member Ray via his meeting link to <a href="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus" className="underline"> find out more </a>.</div>,
  },
  {
    title: "When and why do I need to start planning for e-invoicing?",
    content:
      <div><div className="mb-2"> In Nov 2019 , Treasury released a consultation paper on “Options to mandate e-invoicing for all businesses” seeking feedback from all business owners by Monday 18th Jan 2021. LUCA Plus prepared their feedback on behalf of SME’s, Accountants/Bookkeepers and Auditors who participated in a short survey in support of our submission to Government.You can keep informed of results and join the conversation via our LinkedIn e-invoicing community group here <a href="https://go.luca.plus/linkedin-einvoicing-community-group" className="underline">https://go.luca.plus/linkedin-einvoicing-community-group</a></div>Treasury Consultation Paper Original source here <a href="https://go.luca.plus/have-your-say-consultation-paper" className="underline">https://go.luca.plus/have-your-say-consultation-paper</a>.</div>,
  },

  {
    title: "Who is LUCA Plus?",
    content:
      <div><div className='mb-2'>We are an e-invoicing software network offering automation across accounting platforms. A game changer for accounting technology and Accredited by the Australian Taxation Office (ATO), LUCA Plus e-invoicing software offers the security and convenience of the internationally validated Peppol system, reducing the risk of invoice fraud. </div>LUCA Plus has received broad-reaching recognition including: 2019 Finalist Accountancy Software of the Year, Member of the Melbourne Accelerator Program, 2019 Winner of the EY Foundry program & winner, Alibaba Entrepreneurs Fund/HSBC JUMPSTARTER 2020 Global Pitch Competition and now a Finalist at the 2020 Finnies Fintech Australia Awards.</div>,
  },
  {
    title: "How does LUCA Plus and MYOB work together?",
    content:
      <div>LUCA Plus and MYOB have joined forces to provide an innovative e-invoicing and accounting software solution all in one, for SMEs to help them create customers for life, ease their cash flow and improve system security. Once the e-invoicing function is enabled, suppliers will automate and streamline bookkeeping efforts for future invoices sent, essentially saving time and money for their customers.</div>,
  },
  {
    title: "What data does LUCA Plus see when I connect my MYOB <br /> software: AccountRight (browser), Essentials (new)?",
    content:
      <div><div className='mb-2'>LUCA Plus only uses the payments and invoicing data already in your accounting platform. It does not, for instance, access balance sheets, or profit and loss data. By syncing your accounts receivable and accounts payable data, LUCA Plus is able to create the ‘live’ encrypted data link between you and your debtors and creditors. It’s what creates all the magic of the LUCA Plus system. </div> (It’s important to note that LUCA Plus is an authorised partner with MYOB  and that such technology partners must undergo the highest level of scrutiny).</div>,
  },
]
