import React from "react"
import logoImage from "../../images/new-landing/logo.svg"
import { meetingUrl } from "../../constants/url"

export default function Top({partner = false, toggleVideoModal, navItemClick}) {
  return (
    <div className="top">
      <div className="container">
        <div className="top-header flex justify-between items-center mx-auto">
          <a href="/" className="logo ml-0 sm:mx-0 flex items-center">
            <img src={logoImage} alt="logo" className="pr-2"/>
            <div className="text-brand whitespace-no-wrap ml-2 hidden sm:block">
              E-invoicing solution
            </div>
          </a>
          <div className="right2">
            <a href="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus" onClick={() => navItemClick("Contact Us")} className="hover:text-gray-26 text-15">
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
