import React, {useEffect, useState} from "react"
import {useCookies} from "react-cookie";
import ReactGA from "react-ga"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Plans from "../components/myob/plan"

import Trusts2 from "../components/myob/trusts2"
import "../scss/style.scss"

import Hero from "../components/myob/hero"
import Accolades from "../components/new-landing/accolades"
import About from "../components/new-landing/about"
import Faq from "../components/myob/faq"
import Why from "../components/new-landing/why"
import Fans from "../components/new-landing/fans"
import Demo from "../components/new-landing/demo"
import Team from "../components/new-landing/team"
import Feats from "../components/myob/feats"
import Modali, { useModali } from "../components/modal"
import {initializeAnalytics} from "../utils";
import Popup, {useModal} from "../components/popup";
import {GetCode3} from "../components/getcode";
import Layout from "../components/layout";
import Top from "../components/myob/top";
import Awards from "../components/myob/awards";
import Fuction from "../components/qbo/function";
import seoImage from "../images/myob/seo-bg.jpg"
import xIconImg from "../images/x-circle.svg";
import {Link} from "gatsby";
import specialOfferBtnImg from "../images/special-offer-btn.svg";
import specialOfferImg from "../images/special-offer.svg";
import footerLeftVectorImg from "../images/footer-left-vector.svg"
import footerRightVectorImg from "../images/footer-right-vector.svg"

export default function NewLandingPage() {
    const [videoModal, toggleVideoModal] = useModali()
    const [topVideoModal, toggleTopVideoModal] = useModali()
    const [toggle, setToggle] = useState(false)
    const [cookies, setCookie] = useCookies(['signupPagePopup']);
    const [modalOptions, toggleModal] = useModal()
    const [registrationDetails, setRegistrationDetails] = useState(null);

    const topBannerClick = () => {
        ReactGA.event({
            category: 'Myob_30 day_Banner',
            action: 'Myob_30 day_Banner clicked'
        });
        gotoPricing();
    }

    const clickOfferBtn = () => {
        ReactGA.event({
            category: 'Myob_30 day_Widget',
            action: 'Myob_30 day_Widget clicked'
        });
        gotoPricing();
    }

    const gotoPricing = () => {
        setRegistrationDetails({isPromoCode: true})
        try {
            document.querySelector('.new-pricing__hero').scrollIntoView({
                behavior: 'smooth',
                top: 0,
                left: 0,
            });
        } catch (error) {
            window.scrollTo(0, 0);
        }
    }

    const navItemClick = (navItem) => {
        ReactGA.event({
            category: 'NAV_ITEM',
            action: `Clicked ${navItem} Nav Item of Myob Page`
        });
    }

    return (
        <div className="h-full font-robo new-landingpage myob">
            <SEO title="MYOB E-invoicing | LUCA+ Australia" einvoice={true} description="Existing MYOB users can integrate LUCA Plus to start e-invoicing with MYOB AccountRight (browser) and MYOB Essentials (new) - saving time and money, whilst eliminating fraud and scams." 
            keywords="MYOB e-invoicing" image={seoImage}/>
            <Top navItemClick={navItemClick}/>

          <div className="footer-banner">
            <div className="text">
              Try LUCA Plus FREE for 30 days
            </div>
            <div className="flex">
              <img src={footerLeftVectorImg} />
              <div onClick={topBannerClick} className="btn font-medium">
                Get Started
              </div>
              <img src={footerRightVectorImg} />
            </div>
          </div>

            <Hero toggleTopVideoModal={toggleTopVideoModal}/>

            <div className="relative special-offer-btn">
                <div onClick={clickOfferBtn}>
                    <img src={specialOfferBtnImg} className="absolute image cursor-pointer" />
                </div>
                <img src={specialOfferImg} />
            </div>

            <Plans registrationDetails={registrationDetails} page="Myob" />
            <Faq toggleVideoModal={toggleVideoModal} />
            <Fans myob={true} />
            <Feats />
            <About />
            <Awards />
            <Fuction />
            <Trusts2 />
            <Footer simple={true} myob={true} page="Myob"/>
            <Modali.Modal {...videoModal}>
                <div className="hero-video">
                    <iframe
                        src="https://www.youtube.com/embed/L8Q7ScsjLtQ?autoplay=1&enable_js=1"
                        title="Introduction to LUCA+"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                    />
                </div>
            </Modali.Modal>
            <Modali.Modal {...topVideoModal}>
                <div className="hero-video">
                    <iframe
                        src="https://www.youtube.com/embed/qon4nDpU858?autoplay=1&enable_js=1"
                        title="MYOB"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        frameBorder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowFullScreen
                    />
                </div>
            </Modali.Modal>
            <Popup.Modal {...modalOptions}>
                <GetCode3 close={toggleModal} />
            </Popup.Modal>
        </div>
    )
}
