import React from "react"

import r1 from "../../images/new-landing/r1.svg"
import r2 from "../../images/new-landing/r2.svg"
import r3 from "../../images/new-landing/r3.svg"
import r4 from "../../images/new-landing/r4.svg"

export default function Awards() {
  return (
    <div className="awards">
      <div className="container recieve">
        <div className="title">LUCA Plus awards and recognition </div>
        <div className="flex flex-col sm:flex-row justify-around items-center mt-12 ">
          <div>
            <div className="logo1">
              <img src={r1} alt="r1" />
            </div>
            <div className="desc">
              2019 ‘Accountancy Software of the Year’ Finalist
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="logo2 flex flex-col justify-center items-center">
              <img src={r2} alt="r2" />
            </div>

            <div className="desc ">
              2020 Global Finalist for the Jumpstarter Pitch Competition
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="logo2 logo3 flex flex-col justify-center items-center">
              <img src={r3} alt="r3" />
            </div>
            <div className="desc ">
              Member of Melbourne Accelerator (Australia’s leading start up program)
            </div>
          </div>
          <div className="mt-12 sm:mt-0">
            <div className="logo2 logo3 flex flex-col justify-center items-center">
              <img src={r4} alt="r4" />
            </div>
            <div className="desc desc4">
              2020 Australia Fintech Awards Finalist
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
