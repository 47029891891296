import React from "react"
import { getStartUrl } from "../../constants/url"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import c1 from "../../images/new-landing/feats/c1.svg"
import c2 from "../../images/new-landing/feats/c2.svg"
import c3 from "../../images/new-landing/feats/c3.svg"
import s1 from "../../images/new-landing/feats/s1.svg"
import s2 from "../../images/new-landing/feats/s2.svg"
import s3 from "../../images/new-landing/feats/s3.svg"
import ReactGA from "react-ga";

export default function Feats() {
  const requestDemoClick = () => {
    ReactGA.event({
      category: `Myob_Request demo2_Green button`,
      action: `Myob_Request demo2_Green button clicked`
    });
  }
  return (
    <div className="container feats pb-16 sm:pb-32 sm:mt-6">
      <div className="title">
        Integrate LUCA Plus with your MYOB AccountRight (browser)<p className="contents sm:hidden"><br /></p>
        {" "} and MYOB Essentials (new) account today
      </div>
      <div className="sm:w-4/5 w-full mx-auto text-center">
        <div className="mt-0 sm:mt-16 feats-icons flex sm:flex-row flex-col justify-around items-center">
          <div className="one ">
            <img src={c1} alt="c1" className="mx-auto" />
            <div className="mt-0 sm:mt-8 feats-info sm:text-center text-left">
              Know When You’re Getting Paid
            </div>
            <div className="divide "></div>
          </div>
          <div className="one mt-3 sm:mt-auto">
            <img src={c2} alt="c2" className="mx-auto" />
            <div className="mt-0 sm:mt-8 feats-info sm:text-center text-left">Get Paid 32% Faster</div>
            <div className="divide"></div>
          </div>
          <div className="one mt-3 sm:mt-auto">
            <img src={c3} alt="c3" className="mx-auto" />
            <div className="mt-0 sm:mt-8 feats-info sm:text-center text-left">Avoid Invoice Fraud</div>
          </div>
        </div>
      </div>

      <div className="mt-3 sm:mt-20 w-4/5 mx-auto text-center">
        <div className="mt-0 sm:mt-16 feats-icons flex sm:flex-row flex-col justify-around items-center">
          <div className="one ">
            <img src={s1} alt="s1" className="mx-auto" />
            <div className="mt-0 sm:mt-8 feats-info sm:text-center text-left">
              Eliminate Invoice Errors
            </div>
            <div className="divide "></div>
          </div>
          <div className="one mt-3 sm:mt-auto">
            <img src={s2} alt="s2" className="mx-auto" />
            <div className="mt-0 sm:mt-8 feats-info sm:text-center text-left">
              Automate Your Bookkeeping
            </div>
            <div className="divide"></div>
          </div>
          <div className="one mt-3 sm:mt-auto">
            <img src={s3} alt="s3" className="mx-auto" />
            <div className="mt-0 sm:mt-8 feats-info sm:text-center text-left">
              Save Up to 10 Hours Per Week
            </div>
          </div>
        </div>
      </div>

      <div className="mt-16">
        <a
          onClick={requestDemoClick}
          href="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus"
          className="bg-mind rounded leading-7  focus:outline-none text-white px-24 sm:px-10 py-3 capitalize create-btn"
        >
          Request Demo
        </a>
      </div>
    </div>
  )
}
