import React from "react"

import bg1Img from "../../images/myob/myob-video.png"
import myobImg from "../../images/myob-logo.svg"
import lucaplusImg from "../../images/logo.svg"
import crossIconImage from "../../images/myob/cross-icon.svg";
import ReactGA from "react-ga";
const getStartUrl = "/signup/"
export default function Hero({toggleTopVideoModal}) {
  const goPricingPage = e => {
    e.preventDefault()
    try {
      document.querySelector('.new-pricing__hero').scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
    ReactGA.event({
      category: `Myob_Select plan_Green button`,
      action: `Myob_Select plan_Green button clicked`
    });
  }
  return (
    <div className="qbo__hero">
      <div className="hero-inner container flex justify-between items-center mx-auto">
        <div className="left text-center sm:text-left mt-0 sm:mt-8">
          <div className="title ">
            Try e-invoicing and slay the invoicing monster today!
          </div>
          <div className="desc mt-6">
            Existing MYOB users can integrate LUCA Plus to start e-invoicing
          </div>
          <div className="flex justify-center sm:justify-start items-center mt-6">
            <button
              className="signup-btn focus:outline-none"
              onClick={goPricingPage}
            >
              Select Plan
            </button>
          </div>
          <div className="flex mt-6 logo items-center">
            <img src={myobImg} alt="myob" className="image mr-8" />
            <img src={crossIconImage} alt="cross" className="cross-img mr-8" />
            <img src={lucaplusImg} alt="lucaplus" className="image lucaplus-img" />
          </div>
        </div>
        <div className="right flex">
          <div className="fvideo mx-auto  w-11/12 xl:w-full">
            {/*<div className="fvideo__play"/>*/}
            <iframe
                src="https://www.youtube.com/embed/qon4nDpU858?autoplay=1&mute=1&enable_js=1&playlist=qon4nDpU858&loop=1"
                title="MYOB"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
            />
          </div>
        </div>
      </div>
    </div>
  )
}
